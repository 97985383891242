<template>
  <button
    v-if="!redirect && !href"
    aria-label="button"
    :class="[
      'font-montserrat ease-out duration-50 flex justify-center items-center overflow-hidden',
      disabled ? 'cursor-default' : 'cursor-pointer',
      roundedClass,
      colorsClass,
      heightClass,
      paddingClass,
      additionalClasses,
    ]"
    :disabled="disabled || isLoading"
    @click.prevent="onClick"
  >
    <img
      v-if="isLoading"
      src="@/assets/icons/spinner.svg"
      alt="spinner"
      class="animate-spin h-5 w-5 mr-3 absolute"
    />
    <div
      :class="[
        isLoading ? 'opacity-0' : 'flex items-center',
        height === 'big' ? 'gap-2' : 'gap-1.5',
      ]"
    >
      <i
        v-if="leftIcon"
        :class="[
          height ? 'text-xl leading-5' : 'text-base leading-4',
          leftIcon,
        ]"
      />
      <slot />
      <i
        v-if="rightIcon"
        :class="[
          height ? 'text-xl leading-5' : 'text-base leading-4',
          rightIcon,
        ]"
      />
    </div>
  </button>
  <router-link
    v-else-if="redirect"
    :to="redirect"
    aria-label="button"
    :class="[
      'font-montserrat ease-out duration-50 flex justify-center items-center overflow-hidden',
      disabled ? 'cursor-default' : 'cursor-pointer',
      roundedClass,
      colorsClass,
      heightClass,
      paddingClass,
      additionalClasses,
    ]"
    :disabled="disabled || isLoading"
  >
    <img
      v-if="isLoading"
      src="@/assets/icons/spinner.svg"
      alt="spinner"
      class="animate-spin h-5 w-5 mr-3 absolute"
    />
    <div
      :class="[
        isLoading ? 'opacity-0' : 'flex items-center',
        height === 'big' ? 'gap-2' : 'gap-1.5',
      ]"
    >
      <i
        v-if="leftIcon"
        :class="[
          height ? 'text-xl leading-5' : 'text-base leading-4',
          leftIcon,
        ]"
      />
      <slot />
      <i
        v-if="rightIcon"
        :class="[
          height ? 'text-xl leading-5' : 'text-base leading-4',
          rightIcon,
        ]"
      />
    </div>
  </router-link>
  <Link
    v-else
    :href="href"
    aria-label="button"
    :class="[
      'font-montserrat ease-out duration-50 flex justify-center items-center overflow-hidden',
      disabled ? 'cursor-default' : 'cursor-pointer',
      roundedClass,
      colorsClass,
      heightClass,
      paddingClass,
      additionalClasses,
    ]"
    :target="target"
    :disabled="disabled || isLoading"
  >
    <slot />
  </Link>
</template>
<script setup lang="ts">
import { computed } from "vue";
import Link from "@/components/layout/buttons/Link.vue";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  additionalClasses: {
    type: String,
    default: "",
  },
  redirect: {
    type: String,
    default: "",
  },
  href: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "orange",
    validator: (prop: string) =>
      [
        "orange",
        "white",
        "light-gray",
        "dark-gray",
        "trans",
        "outlined",
        "outlined-white",
        "inverted-primary",
        "accent-green",
        "new-orange",
      ].includes(prop),
  },
  height: {
    type: String,
    default: "big",
    validator: (prop: string) => ["small", "big"].includes(prop),
  },
  icons: {
    type: Boolean,
    default: false,
  },
  leftIcon: {
    type: Array,
  },
  rightIcon: {
    type: Array,
  },
  roundedClass: {
    type: String,
    default: "rounded-54",
  },
  target: {
    type: String,
    default: "_blank",
  },
});
const emit = defineEmits(["buttonClick"]);

const colorsClass = computed(() => getColors());
const heightClass = computed(() =>
  props.height === "big"
    ? "h-12 text-base font-bold"
    : "h-8 text-sm font-semibold",
);
const paddingClass = computed(() => getPadding());

function onClick(): void {
  if (props.redirect) {
    if (!process.server) window.location.href = props.redirect;
  } else {
    emit("buttonClick");
  }
}

function getColors(): string {
  switch (props.color) {
    case "orange":
      return "bg-accent-orange text-grayscale-7 hover:bg-accent-orange-hover border border-transparent box-border active:border-grayscale-96";
    case "new-orange":
      return "bg-accent-orange text-grayscale-5 hover:bg-accent-orange-hover border border-transparent box-border active:border-grayscale-96";
    case "white":
      return "bg-grayscale-96 text-grayscale-7 hover:bg-grayscale-95 border border-transparent box-border active:border-grayscale-96";
    case "light-gray":
      return "bg-grayscale-16 text-grayscale-96 hover:bg-grayscale-30 border border-transparent box-border active:border-grayscale-96";
    case "dark-gray":
      return "bg-grayscale-7 text-grayscale-96 hover:bg-grayscale-13 border border-transparent box-border active:border-grayscale-96";
    case "trans":
      return "bg-transparent text-grayscale-96 border border-grayscale-30 hover:border-grayscale-96 active:border-grayscale-96";
    case "outlined":
      return "bg-transparent text-grayscale-96 border border-grayscale-30 hover:border-grayscale-96 active:border-grayscale-96";
    case "outlined-white":
      return "bg-transparent text-grayscale-7 hover:bg-grayscale-95 border border-grayscale-30";
    case "inverted-primary":
      return "bg-grayscale-7 text-grayscale-96 hover:bg-grayscale-13 border border-transparent box-border active:border-grayscale-96";
    case "accent-green":
      return "bg-spotify-green text-grayscale-7 hover:text-grayscale-96 border border-transparent box-border active:border-grayscale-96";
    default:
      return "bg-accent-orange text-grayscale-96 hover:bg-accent-orange-hover border border-transparent box-border active:border-grayscale-96";
  }
}

function getPadding(): string {
  if (props.icons) {
    return props.height === "big" ? "px-6" : "px-4";
  } else {
    return props.height === "big" ? "px-8" : "px-6";
  }
}
</script>
